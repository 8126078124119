import { inject, Injectable } from '@angular/core';
import { Configuration } from '@icp/mbp-cirklo-api-client';

@Injectable({ providedIn: 'root' })
export class OpenApiConfiguration {
    private config = inject(Configuration);

    hasCredentials() {
        const creds = this.config.credentials;
        return typeof creds === 'object' && typeof creds['sessionId'] === 'string';
    }

    setSession(sessionId: string | null) {
        if (sessionId) {
            this.config.credentials = { sessionId: `Bearer ${sessionId}` };
        } else {
            this.config.credentials = {};
        }
    }
}
