import { Route } from '@angular/router';
import { TranslationKey } from '@icp/angular/i18n';

import { BottomNavigationComponent } from './core/bottom-navigation/bottom-navigation.component';
import { ensureUserGuard } from './core/ensure-user.guard';

export const appRoutes: Route[] = [
    {
        path: 'create-user',
        title: 'CREATE_ACCOUNT' as TranslationKey,
        loadComponent: () => import('./user/create-cirklo-user/create-cirklo-user.component'),
    },
    {
        path: '',
        canActivate: [ensureUserGuard],
        component: BottomNavigationComponent,
        children: [
            { path: '', redirectTo: 'vouchers', pathMatch: 'full' },
            {
                path: 'vouchers',
                title: 'VOUCHERS' as TranslationKey,
                children: [
                    { path: '', loadComponent: () => import('./vouchers/vouchers-list/vouchers-list.component') },
                    {
                        path: 'add',
                        title: 'SCAN_VOUCHER' as TranslationKey,
                        data: {
                            backLinkLabel: 'VOUCHERS' as TranslationKey,
                        },
                        loadComponent: () => import('./vouchers/scan-voucher/scan-voucher.component'),
                    },
                    {
                        path: ':voucherId',
                        title: 'VOUCHER_DETAILS' as TranslationKey,
                        data: {
                            backLinkLabel: 'VOUCHERS' as TranslationKey,
                        },
                        loadComponent: () => import('./vouchers/voucher-details/voucher-details.component'),
                    },
                ],
            },
            {
                path: 'merchants',
                loadChildren: () => import('./merchants/merchants.routes'),
            },
            { path: 'buy', loadComponent: () => import('./buy-voucher/buy-voucher.component') },
        ],
    },
];
