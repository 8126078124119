import { provideHttpClient, withFetch } from '@angular/common/http';
import {
    ApplicationConfig,
    effect,
    ErrorHandler,
    inject,
    Injectable,
    provideAppInitializer,
    provideExperimentalZonelessChangeDetection,
    Signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    provideRouter,
    Router,
    RouterStateSnapshot,
    TitleStrategy,
    withComponentInputBinding,
    withRouterConfig,
} from '@angular/router';
import { provideLanguageService } from '@icp/angular/i18n';
import { ThemeMainColorType, ThemingService } from '@icp/angular/theming';
import { BASE_PATH, Configuration } from '@icp/mbp-cirklo-api-client';
import { TranslocoLoader, TranslocoService } from '@jsverse/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { BehaviorSubject, of, switchMap } from 'rxjs';

import { environment } from '../environment/environment';
import { appRoutes } from './app.routes';
import { MbpService } from './core/mbp.service';
import { SharedEffects } from './core/shared.effects';
import { sharedFeature } from './core/shared.feature';

async function initApp(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    themingService: ThemingService,
    mbpService: MbpService,
    _: TraceService, // Needs to be included to ensure Sentry is loaded
) {
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
    iconRegistry.addSvgIcon('redeem', sanitizer.bypassSecurityTrustResourceUrl('/assets/icon/redeem.svg'));
    const mbpClient = environment.mbpClient;
    await mbpClient.connect();
    const [, tenant] = await Promise.all([mbpClient.ui.setTitle('Cirklo'), mbpClient.context.getTenant()]);
    themingService.setupTheme({
        [ThemeMainColorType.PRIMARY]: tenant.branding.primaryColor,
        [ThemeMainColorType.ACCENT]: tenant.branding.actionColor,
    });
    mbpService.tenant = tenant;
}

class TranslocoJsLoader implements TranslocoLoader {
    getTranslation(lang: string) {
        return import(`../i18n/${lang}.json`, { assert: { type: 'json' } });
    }
}

@Injectable({ providedIn: 'root' })
class MbpTitleStrategy extends TitleStrategy {
    private title = inject(Title);
    private transloco = inject(TranslocoService);
    titleKey = new BehaviorSubject<string | null>(null);
    titleTranslation: Signal<string> = toSignal(
        this.titleKey.pipe(switchMap((key) => (key ? this.transloco.selectTranslate(key) : of('Cirklo')))),
    );

    constructor() {
        super();
        effect(() => {
            const title = this.titleTranslation();
            this.title.setTitle(title);
            environment.mbpClient.ui.setTitle(title);
        });
    }

    override updateTitle(snapshot: RouterStateSnapshot) {
        this.titleKey.next(this.buildTitle(snapshot) ?? null);
    }
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimationsAsync(window.matchMedia('(prefers-reduced-motion: reduce)').matches ? 'noop' : 'animations'),
        provideExperimentalZonelessChangeDetection(),
        provideRouter(
            appRoutes,
            withRouterConfig({ paramsInheritanceStrategy: 'always' }),
            withComponentInputBinding(),
        ),
        provideStore({ [sharedFeature.name]: sharedFeature.reducer }),
        provideEffects(SharedEffects),
        provideLanguageService({ selector: sharedFeature.selectLanguage, loader: TranslocoJsLoader }),
        {
            provide: ErrorHandler,
            useValue: createErrorHandler({
                showDialog: true,
                dialogOptions: { lang: navigator.language },
                logErrors: true,
            }),
        },
        { provide: TraceService, deps: [Router] },
        provideAppInitializer(() =>
            initApp(
                inject(MatIconRegistry),
                inject(DomSanitizer),
                inject(ThemingService),
                inject(MbpService),
                inject(TraceService),
            ),
        ),
        provideHttpClient(withFetch()),
        { provide: BASE_PATH, useValue: '' },
        { provide: TitleStrategy, useClass: MbpTitleStrategy },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: 'outline' } satisfies MatFormFieldDefaultOptions,
        },
        { provide: Configuration, useFactory: () => new Configuration() },
    ],
};
