import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { TranslationKey } from '@icp/angular/i18n';
import { TranslocoService } from '@jsverse/transloco';

import { environment } from '../environment/environment';

const ROOT_URLS = ['/vouchers', '/merchants', '/buy', '/create-user'];

@Component({
    imports: [RouterOutlet],
    selector: 'mbp-c-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent {
    private location = inject(Location);
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private matDialog = inject(MatDialog);
    private transloco = inject(TranslocoService);

    constructor() {
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                const backLabel = this.route.snapshot.data['backLinkLabel'] ?? ('OVERVIEW' as TranslationKey);
                environment.mbpClient.ui.setBacklinkLabel(this.transloco.translate(backLabel)).catch((e) => alert(e));
            }
        });
        environment.mbpClient.navigation.onBackNavigation((): boolean => {
            const openDialogCount = this.matDialog.openDialogs.length;
            if (openDialogCount) {
                this.matDialog.openDialogs[openDialogCount - 1].close();
                return false;
            }
            if (!ROOT_URLS.includes(this.router.url)) {
                this.location.back();
                return false;
            }
            return true;
        });
    }
}
