import { createMbpEmbedClient } from '@govflanders/mbp-embed-sdk';
import { BrowserOptions } from '@sentry/angular';

export const environment = {
    production: true,
    env: 'staging',
    mbpClient: createMbpEmbedClient('2f9445c2-d1d9-4ef2-abb7-02eddfb7514b'),
    googleMapsKey: 'AIzaSyC2L7_cyi7Bs8-UrZIpOKVaUKWl2_17LGo',
    sentry: {
        replaysSessionSampleRate: 1.0,
    } satisfies BrowserOptions,
};
