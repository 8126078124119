import { getBrowserSupportedLanguage } from '@icp/angular/i18n';
import {
    initialStateResult,
    removeItem,
    ResultState,
    selectIsStateLoading,
    selectResult,
    stateError,
    stateLoading,
    stateSuccess,
} from '@icp/angular/ngrx';
import { SupportedLanguage } from '@icp/interfaces';
import { GetUserDto, TenantSettingsDto, UserVoucherListDTO } from '@icp/mbp-cirklo-api-client';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import {
    addCirkloVoucher,
    addCirkloVoucherFailed,
    addCirkloVoucherSuccess,
    createCirkloUser,
    createCirkloUserFailed,
    createCirkloUserSuccess,
    deleteCirkloVoucher,
    deleteCirkloVoucherFailed,
    deleteCirkloVoucherSuccess,
    getCirkloUser,
    getCirkloUserFailed,
    getCirkloUserSuccess,
    getTenantSettings,
    getTenantSettingsFailed,
    getTenantSettingsSuccess,
    listCirkloVouchers,
    listCirkloVouchersFailed,
    listCirkloVouchersSuccess,
} from './shared.actions';

interface State {
    user: ResultState<GetUserDto | null>; // in case of null no user exists yet
    vouchers: ResultState<UserVoucherListDTO>;
    tenantSettings: ResultState<TenantSettingsDto>;
    language: SupportedLanguage;
}

const initialState: State = {
    user: initialStateResult,
    vouchers: initialStateResult,
    tenantSettings: initialStateResult,
    language: getBrowserSupportedLanguage(),
};

export const sharedFeature = createFeature({
    name: 'shared',
    reducer: createReducer(
        initialState,
        on(
            getCirkloUser,
            createCirkloUser,
            (state): State => ({
                ...state,
                user: stateLoading(state.user.result),
            }),
        ),
        on(
            getCirkloUserSuccess,
            createCirkloUserSuccess,
            (state, action): State => ({
                ...state,
                user: stateSuccess(action.payload),
            }),
        ),
        on(
            getCirkloUserFailed,
            createCirkloUserFailed,
            (state, action): State => ({
                ...state,
                user: stateError(action.error, state.user.result),
            }),
        ),
        on(
            listCirkloVouchers,
            (state): State => ({
                ...state,
                vouchers: stateLoading(state.vouchers.result),
            }),
        ),
        on(
            listCirkloVouchersSuccess,
            (state, action): State => ({
                ...state,
                vouchers: stateSuccess(action.payload),
            }),
        ),
        on(listCirkloVouchersFailed, (state, action) => ({
            ...state,
            vouchers: stateError(action.error, state.vouchers.result),
        })),
        on(addCirkloVoucher, (state) => ({
            ...state,
            vouchers: stateLoading(state.vouchers.result),
        })),
        on(
            addCirkloVoucherSuccess,
            (state, action): State => ({
                ...state,
                vouchers: stateSuccess({
                    ...state.vouchers.result!,
                    vouchers: [...state.vouchers.result!.vouchers, ...action.payload],
                }),
            }),
        ),
        on(
            addCirkloVoucherFailed,
            (state, action): State => ({
                ...state,
                vouchers: stateError(action.error, state.vouchers.result),
            }),
        ),
        on(deleteCirkloVoucher, (state) => ({
            ...state,
            vouchers: stateLoading(state.vouchers.result),
        })),
        on(
            deleteCirkloVoucherSuccess,
            (state, action): State => ({
                ...state,
                vouchers: stateSuccess({
                    ...state.vouchers.result!,
                    vouchers: removeItem(state.vouchers.result!.vouchers, action.voucherId, 'id'),
                }),
            }),
        ),
        on(
            deleteCirkloVoucherFailed,
            (state, action): State => ({
                ...state,
                vouchers: stateError(action.error, state.vouchers.result),
            }),
        ),

        on(
            getTenantSettings,
            (state): State => ({
                ...state,
                tenantSettings: stateLoading(initialState.tenantSettings.result),
            }),
        ),
        on(
            getTenantSettingsSuccess,
            (state, action): State => ({
                ...state,
                tenantSettings: stateSuccess(action.payload),
            }),
        ),
        on(
            getTenantSettingsFailed,
            (state, action): State => ({
                ...state,
                tenantSettings: stateError(action.error, state.tenantSettings.result),
            }),
        ),
    ),
});

export const selectCurrentCirkloUserResult = sharedFeature.selectUser;
export const selectCurrentUserLoading = createSelector(sharedFeature.selectUser, selectIsStateLoading);

export const selectCirkloVouchers = createSelector(sharedFeature.selectVouchers, selectResult);
export const selectSortedCirkloVouchers = createSelector(
    selectCirkloVouchers,
    (result): UserVoucherListDTO => ({
        vouchers: [...(result?.vouchers ?? [])].reverse(),
        pendingOcaVouchers: result?.pendingOcaVouchers ?? [],
    }),
);
export const selectCirkloVouchersLoading = createSelector(sharedFeature.selectVouchers, selectIsStateLoading);
export const selectTotalAvailableAmount = createSelector(selectCirkloVouchers, (vouchers) => {
    let total = 0;
    for (const voucher of vouchers?.vouchers ?? []) {
        const expiredDate = voucher.expiresAt && new Date(voucher.expiresAt);
        const expired = expiredDate && expiredDate < new Date();
        if (!expired) {
            total += voucher.amount;
        }
    }
    return total;
});

export const selectTenantSettings = createSelector(sharedFeature.selectTenantSettings, selectResult);
