import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@icp/mbp-cirklo-api-client';
import { catchError, map, tap } from 'rxjs';

import { environment } from '../../environment/environment';
import { ErrorService } from './error.service';
import { OpenApiConfiguration } from './openapi-configuration.service';

export const ensureSession: CanActivateFn = (_, state) => {
    const config = inject(OpenApiConfiguration);
    const router = inject(Router);
    const authService = inject(AuthService);
    const errorService = inject(ErrorService);
    if (config.hasCredentials()) {
        return true;
    }
    const parsedUrl = new URL(state.url, window.location.origin);
    const token = parsedUrl.searchParams.get('token')!;
    if (!token) {
        return errorService
            .showErrorDialog('The "token" query parameter was not set, cannot continue.')
            .afterClosed()
            .pipe(
                map(() => false),
                tap(() => environment.mbpClient.navigation.back()),
            );
    }
    parsedUrl.searchParams.delete('token');
    const returnUrl = state.url.split('?')[0] + parsedUrl.search;
    return authService.createAuthSession({ token }).pipe(
        tap((session) => config.setSession(session.sessionId)),
        map(() => router.createUrlTree([returnUrl], { queryParams: parsedUrl.searchParams })),
        catchError((err) =>
            errorService
                .showErrorDialog(errorService.getMessage(err))
                .afterClosed()
                .pipe(
                    map(() => false),
                    tap(() => environment.mbpClient.navigation.back()),
                ),
        ),
    );
};
