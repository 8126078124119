import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { dispatchWhenNotLoaded, selectStateDone } from '@icp/angular/ngrx';
import { Store } from '@ngrx/store';
import { filter, isObservable, map, of, switchMap, tap } from 'rxjs';

import { environment } from '../../environment/environment';
import { ensureSession } from './ensure-session.guard';
import { getCirkloUser } from './shared.actions';
import { selectCurrentCirkloUserResult } from './shared.feature';

export const ensureUserGuard: CanActivateFn = (route, state) => {
    const store = inject(Store);
    const router = inject(Router);
    environment.mbpClient.ui.setStatusLoading(true);
    const sessionResult = ensureSession(route, state);
    const sessionObservable = typeof sessionResult === 'boolean' ? of(sessionResult) : sessionResult;
    if (!isObservable(sessionObservable)) {
        throw new Error('ensureSession must return an observable or boolean');
    }
    return sessionObservable.pipe(
        switchMap((sessionResult) => {
            if (sessionResult === false) {
                environment.mbpClient.ui.setStatusLoading(false);
                return of(router.createUrlTree(['/create-user']));
            }
            dispatchWhenNotLoaded(store, selectCurrentCirkloUserResult, getCirkloUser());
            return store.select(selectCurrentCirkloUserResult).pipe(
                filter(selectStateDone),
                // redirect to the url returned by the sessionResult if all was good
                map((user) => (user.result ? sessionResult : router.createUrlTree(['/create-user']))),
                tap(() => environment.mbpClient.ui.setStatusLoading(false)),
            );
        }),
    );
};
