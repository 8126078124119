import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { skipNullish } from '@icp/angular/rxjs-operators';
import { TenantSettingsService, UserService } from '@icp/mbp-cirklo-api-client';
import { TranslocoService } from '@jsverse/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as Sentry from '@sentry/angular';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import { ErrorService } from './error.service';
import { MbpService } from './mbp.service';
import {
    addCirkloVoucher,
    addCirkloVoucherFailed,
    addCirkloVoucherSuccess,
    confirmDeleteCirkloVoucher,
    createCirkloUser,
    createCirkloUserFailed,
    createCirkloUserSuccess,
    deleteCirkloVoucher,
    deleteCirkloVoucherFailed,
    deleteCirkloVoucherSuccess,
    getCirkloUser,
    getCirkloUserFailed,
    getCirkloUserSuccess,
    getTenantSettings,
    getTenantSettingsFailed,
    getTenantSettingsSuccess,
    listCirkloVouchers,
    listCirkloVouchersFailed,
    listCirkloVouchersSuccess,
} from './shared.actions';
import { SimpleDialogComponent, SimpleDialogData, SimpleDialogResult } from './simple-dialog/simple-dialog.component';

@Injectable({ providedIn: 'root' })
export class SharedEffects {
    private actions = inject(Actions);
    private userService = inject(UserService);
    private errorService = inject(ErrorService);
    private router = inject(Router);
    private matDialog = inject(MatDialog);
    private transloco = inject(TranslocoService);
    private mbpService = inject(MbpService);
    private tenantSettingsService = inject(TenantSettingsService);

    getUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(getCirkloUser),
            switchMap((action) =>
                this.userService.getCurrentUser().pipe(
                    map((user) => getCirkloUserSuccess({ payload: user })),
                    tap((action) => {
                        if (action.payload) {
                            Sentry.setUser({ id: action.payload.id, email: action.payload.email });
                        }
                    }),
                    catchError((error) => {
                        if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                            return of(getCirkloUserSuccess({ payload: null }));
                        }
                        return this.errorService.handleError(action, getCirkloUserFailed, error);
                    }),
                ),
            ),
        ),
    );

    createCirkloUser$ = createEffect(() =>
        this.actions.pipe(
            ofType(createCirkloUser),
            switchMap((action) =>
                this.userService.createUser(action.payload).pipe(
                    map((user) => createCirkloUserSuccess({ payload: user })),
                    tap(() => this.router.navigateByUrl('/')),
                    tap((action) => Sentry.setUser({ id: action.payload.id, email: action.payload.email })),
                    catchError((error) => this.errorService.handleError(action, createCirkloUserFailed, error)),
                ),
            ),
        ),
    );

    listVouchers$ = createEffect(() =>
        this.actions.pipe(
            ofType(listCirkloVouchers),
            switchMap((action) =>
                this.userService.listUserVouchers().pipe(
                    map((user) => listCirkloVouchersSuccess({ payload: user })),
                    catchError((error) => this.errorService.handleError(action, listCirkloVouchersFailed, error)),
                ),
            ),
        ),
    );

    addVoucher$ = createEffect(() =>
        this.actions.pipe(
            ofType(addCirkloVoucher),
            switchMap((action) =>
                this.userService.addVouchers(action.payload).pipe(
                    map((user) => addCirkloVoucherSuccess({ payload: user })),
                    tap((action) => {
                        const firstVoucher = action.payload[0];
                        if (firstVoucher) {
                            this.router.navigateByUrl(`/vouchers/${firstVoucher.id}`);
                        }
                    }),
                    catchError((error) => this.errorService.handleError(action, addCirkloVoucherFailed, error)),
                ),
            ),
        ),
    );

    confirmDeleteVoucher$ = createEffect(() =>
        this.actions.pipe(
            ofType(confirmDeleteCirkloVoucher),
            switchMap((action) =>
                this.matDialog
                    .open<SimpleDialogComponent, SimpleDialogData, SimpleDialogResult>(SimpleDialogComponent, {
                        data: {
                            title: this.transloco.translate('CONFIRMATION'),
                            ok: this.transloco.translate('OK'),
                            cancel: this.transloco.translate('CANCEL'),
                            message: this.transloco.translate('CONFIRM_DELETE_VOUCHER'),
                        },
                    })
                    .afterClosed()
                    .pipe(
                        map((result) =>
                            result?.submitted ? deleteCirkloVoucher({ voucherId: action.voucherId }) : null,
                        ),
                        skipNullish,
                    ),
            ),
        ),
    );

    deleteCirkloVoucher$ = createEffect(() =>
        this.actions.pipe(
            ofType(deleteCirkloVoucher),
            switchMap((action) =>
                this.userService.deleteVoucher(action.voucherId).pipe(
                    map((user) => deleteCirkloVoucherSuccess({ voucherId: action.voucherId })),
                    tap(() => this.router.navigateByUrl('/vouchers')),
                    catchError((error) => this.errorService.handleError(action, deleteCirkloVoucherFailed, error)),
                ),
            ),
        ),
    );

    loadDefaultMapPosition$ = createEffect(() =>
        this.actions.pipe(
            ofType(getTenantSettings),
            switchMap((action) =>
                this.tenantSettingsService.getTenantSettings(this.mbpService.tenant.id).pipe(
                    map((voucherTypes) => getTenantSettingsSuccess({ payload: voucherTypes })),
                    catchError((error) => this.errorService.handleError(action, getTenantSettingsFailed, error)),
                ),
            ),
        ),
    );
}
