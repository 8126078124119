import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { TranslationKey } from '@icp/angular/i18n';
import { dispatchWhenNotLoaded } from '@icp/angular/ngrx';
import { TranslocoDirective } from '@jsverse/transloco';
import { Store } from '@ngrx/store';

import { getTenantSettings } from '../shared.actions';
import { selectTenantSettings, sharedFeature } from '../shared.feature';

@Component({
    selector: 'mbp-c-bottom-navigation',
    imports: [
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        RouterLink,
        RouterOutlet,
        TranslocoDirective,
        MatRippleModule,
        RouterLinkActive,
    ],
    templateUrl: './bottom-navigation.component.html',
    styleUrl: './bottom-navigation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomNavigationComponent {
    private store = inject(Store);
    tenantSettings = this.store.selectSignal(selectTenantSettings);
    buttons = computed(() => {
        const pages = [
            { icon: 'wallet', route: 'vouchers', label: 'VOUCHERS' as TranslationKey },
            { icon: 'storefront', route: 'merchants', label: 'MERCHANTS' as TranslationKey },
        ];
        if (this.tenantSettings()?.webshopUrl) {
            pages.push({ icon: 'shopping_cart', route: 'buy', label: 'BUY_VOUCHER' as TranslationKey });
        }
        return pages;
    });

    constructor() {
        dispatchWhenNotLoaded(this.store, sharedFeature.selectTenantSettings, getTenantSettings());
    }
}
